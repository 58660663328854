<template>
  <div>
    <r-som-step
      v-show='selectedItem=="r_som"'
      @is-computed="isComputed"
      :analysisObj="getAnalysisForStep('r_som')"
      ref="somStep"/>
    <div v-show="objectSom!=''">
      <r-plot-som-ind v-show='selectedItem=="r_plotsomind"'
        :object-som="objectSom"
        :datasetName="datasetName"
        ref="somind"
      >
      </r-plot-som-ind>
      <r-plot-som-proto
        v-show='selectedItem=="r_plotsomproto"'
        :object-som="objectSom"
        :datasetName="datasetName"
        ref="somproto">
      </r-plot-som-proto>
      <r-plot-super-cluster
        v-if='selectedItem=="r_cut_supercluster"'
        ref="super"
        :object-som="objectSom"
        :analysisObj="getAnalysisForStep('r_cut_supercluster')"
        :dataset="datasetName"
        >
      </r-plot-super-cluster>
    </div>
  </div>
</template>

<script>
import { menu } from '@/mixins/menu'
import { workflow } from '@/mixins/workflow'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useMenuStore } from '@/stores/menu'
export default {
  name: 'RSOM',
  components: {
    'r-som-step': () => import('@/components/ranalyses/RSOMStep.vue'),
    'r-plot-som-ind': () => import('@/components/ranalyses/RPlotsomind.vue'),
    'r-plot-som-proto': () => import('@/components/ranalyses/RPlotsomproto.vue'),
    'r-plot-super-cluster': () => import('@/components/ranalyses/RPlotsomsuper.vue')
  },
  mixins: [menu, workflow],
  data () {
    return {
      menuItems: [
        {
          title: 'Self-Organizing Map',
          name: 'r_som',
          disabled: false
        },
        {
          title: 'Explore individuals',
          name: 'r_plotsomind',
          disabled: true
        },
        {
          title: 'Explore prototypes',
          name: 'r_plotsomproto',
          disabled: true
        },
        {
          title: 'Superclustering',
          name: 'r_cut_supercluster',
          disabled: true
        }
      ],
      graphicals: [],
      objectSom: '',
      objectClusters: '',
      datasetName: ''
    }
  },
  computed: {
    analyses: function () {
      return this.workspaceStore.analyses
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const menuStore = useMenuStore()
    return { workspaceStore, menuStore }
  },
  methods: {
    isComputed: function (data) {
      this.objectSom = data.som
      this.$refs.somproto.reset()
      // this.$refs.super.reset()
      this.objectClusters = data.clusters
      this.$refs.somind.reset()
      this.datasetName = data.datasetName
      this.menuItems[1].disabled = false
      this.menuItems[2].disabled = false
      this.menuItems[3].disabled = false
    }
  },
  mounted () {
    // special case for super_clusting, object is the prepocessed step
    // can be handle has classical analysis.
    if (this.analysisObj !== null && this.analysisObj.meta.func_name === 'r_cut_supercluster') {
      this.menuStore.selectItemName(this.analysisObj.meta.func_name)
      this.menuItems[3].disabled = false
      this.objectSom = this.analysisObj.meta.func_args.datasetName
    }
  }
}
</script>

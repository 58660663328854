import { useWorkspaceStore } from '@/stores/workspacestore'
import { useMenuStore } from '@/stores/menu'
export const workflow = {
  props: {
    analysisObj: {
      type: Object,
      default: null
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const menuStore = useMenuStore()
    return { workspaceStore, menuStore }
  },
  mounted () {
    if (!this.workspaceStore.hasDataset) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.workspaceStore.getWorkflow()
        .then(() => {
          loadingComponent.close()
        })
        .catch(() => {
          loadingComponent.close()
        })
    }
    if (this.$route.params.name) { // back on an analysis
      this.analysisObj = this.workspaceStore.analyses.filter(obj => obj.object_name === this.$route.params.name)[0]
    }
    if (this.analysisObj && this.analysisObj !== null) {
      this.menuStore.selectItemName(this.analysisObj.meta.func_name)
    }
  },
  methods: {
    getAnalysisForStep: function (step) {
      if (this.analysisObj && this.analysisObj !== null) {
        if (step === this.analysisObj.meta.func_name) {
          return (this.analysisObj)
        } else {
          let parent = this.getParentAnalysis()
          if (parent && step === parent.meta.func_name) {
            return parent
          }
        }
      }
      return null
    },
    getParentAnalysis: function () {
      if (this.analysisObj !== null) {
        let previousResult = this.analysisObj.meta.func_args.datasetName
        let previousAnalysis = this.workspaceStore.analyses.filter(a => a.object_name === previousResult)[0]
        return (previousAnalysis)
      } else {
        return null
      }
    },
    getParentCombined: function (previousResult) {
      if (previousResult == null && this.analysisObj !== null) {
        previousResult = this.analysisObj.meta.func_args.datasetName
      }
      let previousAnalysis = this.workspaceStore.combined.filter(a => a.object_name === previousResult)[0]
      if (previousAnalysis) {
        return (previousAnalysis)
      } else {
        return null
      }
    }
  }
}

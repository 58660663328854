
import { useMenuStore } from '@/stores/menu'

export const menu = {
  setup () {
    const menuStore = useMenuStore()
    return { menuStore }
  },
  computed: {
    selectedItem: function () {
      return this.menuStore.selectedItem
    }
  },
  methods: {
    setMenuItems: function (items) {
      this.menuStore.setMenuItems(items)
    },
    selectItem: function () {
      return this.menuStore.selectedItem
    }
  },
  /* watch: {
    menuItems: {
      handler () {
        this.setMenuItems(this.menuItems)
      },
      deep: true
    }
  }, */
  mounted () {
    this.setMenuItems(this.menuItems)
  }
}
